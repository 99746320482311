import classNames from 'classnames';
import React, {useState, useEffect, useRef} from 'react';
import {IoIosTrash, IoMdMore, IoIosClose, IoMdCheckmark} from 'react-icons/io';
import styles from './entryMenu.module.scss';

export const EntryMenu = (props) => {
  const wrapperRef = useRef(null);
  const [active, setActive] = useState(props.active);
  const menuClasses = classNames(styles.menu, {
    [styles.active]: props.active,
    [styles.actionable]: props.actionable,
  });

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      props.onMenuClosed();
    }
  };

  useEffect(() => {
    if (active) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [active]);

  if (active !== props.active) {
    setActive(props.active);
  }

  const actionableClasses = classNames(styles.actionable, styles.menuItem, {
    [styles.completed]: props.completedAt,
  });

  return (
    <div className={menuClasses} ref={wrapperRef}>
      {props.actionable &&
        <button
          className={actionableClasses}
          tabIndex="10000"
          onClick={props.onEntryCompleted}
        >
          <div className={styles.iconWrapper}>
            <IoMdCheckmark />
          </div>
        </button>
      }
      {!props.active &&
        <button
          className={`${styles.menuItem} ${styles.more}`}
          onClick={props.onMenuOpened}
          tabIndex="10000"
        >
          <IoMdMore />
        </button>
      }
      {props.active &&
        <>
          <button
            className={`${styles.menuItem} ${styles.delete}`}
            onClick={props.onEntryDeleted}
          >
            <IoIosTrash />
          </button>
          <button
            className={`${styles.menuItem} ${styles.close}`}
            onClick={props.onMenuClosed}
          >
            <IoIosClose />
          </button>
        </>
      }
    </div>
  );
};
