/**
 * @format
 */
import React, {useContext, useEffect, useState} from 'react';
import {Entry} from 'src/components/entries/entry';
import {EntryActions} from 'src/components/entries/entryActions';
import {ContextBar, ContextItem} from 'src/components/layout/contextBar';
import Layout, {ContentWrapper} from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import {Card} from 'src/components/ui/card';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from 'src/context/globalContextProvider';
import {api} from 'src/shared/api';
import {getSession} from 'src/shared/utilities/session';
import {getProjectFilter} from 'src/shared/utilities/session';
import styles from './index.module.scss';

const projectsFromEntries = (entries, projectFilter) => {
  const projects = {};
  const filter = Object.keys(projectFilter).length > 0;

  entries.forEach((entry) => {
    if (!filter || projectFilter[entry.project]) {
      projects[entry.project] = projects[entry.project] || [];
      projects[entry.project].push(entry);
    }
  });

  return projects;
};

export default () => {
  const session = getSession();
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const [loading, setLoading] = useState(true);
  // const [entries, setEntries] = useState([]);
  const [projects, setProjects] = useState({});
  const [projectFilter] = useState(getProjectFilter());
  const filterActive = Object.keys(projectFilter).length > 0;

  useEffect(() => {
    api()
        .get('/api/dashboard/?actionable=true')
        .then((response) => {
          dispatch({type: 'SET_PROJECTS', projects: response.data.projects});
          dispatch({type: 'SET_ENTRIES', entries: response.data.entries});
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
  }, []);

  useEffect(() => {
    setProjects(projectsFromEntries(state.entries, projectFilter));
  }, [state.entries]);

  return (
    <Layout trialExpired={session && session.trial_expired}>
      <ContextBar>
        <ContextItem text="To Do" />
        {!loading && <EntryActions />}
      </ContextBar>
      <ContentWrapper>
        <SEO />
        {loading && (
          <>
            <Card skeleton />
            <Card skeleton />
            <Card skeleton />
          </>
        )}
        {!loading && filterActive && (
          <div className={styles.filterMessage}>
            These entries are filtered.
          </div>
        )}
        {!loading &&
          Object.keys(projects).map((projectId) => (
            <Card key={`project-${projectId}`} className={styles.project}>
              <div className={styles.title}>
                {state.projectMap[projectId].name}
              </div>
              {projects[projectId].map((entry) => (
                <Entry
                  key={`entry-${entry.pk}`}
                  entryPk={entry.pk}
                  content={entry.content}
                  projectId={entry.project}
                  focused={entry.focused}
                  actionable={entry.actionable}
                />
              ))}
            </Card>
          ))}
      </ContentWrapper>
    </Layout>
  );
};
