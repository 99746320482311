import {Link} from 'gatsby';
import React, {useState} from 'react';
import {IoIosHelpCircleOutline} from 'react-icons/io';
import {Filters} from 'src/components/entries/filters';
import {QuickEntry} from 'src/components/entries/quickEntry';
import {Button} from 'src/components/ui/button';
import {Modal, ModalBody, ModalFooter} from 'src/components/ui/modal/modal';
import styles from './entryActions.module.scss';

export const EntryActions = (props) => {
  return (
    <div className={styles.actions}>
      {props.showQuickEntry &&
        <QuickEntry
          projectMap={props.projectMap}
          onSave={props.onEntrySaved}
        />
      }
    </div>
  );
};
