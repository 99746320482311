import classNames from 'classnames';
import {Link} from 'gatsby';
import React, {useEffect, useState} from 'react';
import styles from './contextBar.module.scss';


export const ContextBar = (props) => {
  return (
    <div className={styles.contextBar}>
      {props.children}
    </div>
  );
};

export const ContextItem = (props) => {
  return (
    <div className={styles.contextItem}>
      <div className={styles.contentWrapper}>
        {props.text}
      </div>
    </div>
  );
};

export const ContextLink = (props) => {
  const classes = classNames(styles.contextItem, styles.contextLink);
  return (
    <Link to={props.to} className={classes}>
      <div className={styles.contentWrapper}>
        {props.text}
      </div>
      {props.divider && <ContextDivider />}
    </Link>
  );
};

export const ContextDivider = (props) => {
  return (
    <div className={styles.divider}>
    </div>
  );
};
