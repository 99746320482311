import autosize from 'autosize';
import classNames from 'classnames';
import moment from 'moment';
import React, {useContext, useState, useRef, useEffect} from 'react';
import {EntryMenu} from 'src/components/entries/entryMenu';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from 'src/context/globalContextProvider';
import {api} from 'src/shared/api';
import styles from './entry.module.scss';

const autosaveMs = 4000;
const autosaveSeconds = autosaveMs / 1000;

const statuses = {
  modified: 'modified',
  saving: 'saving',
  saved: 'saved',
  error: 'error',
};

const getRandomPlaceholder = () => {
  const options = [
    'Start typing...',
    'What\'s on your mind?',
    'Once upon a time...',
    'Type your entry here...',
    'Dear diary...',
    'Hello, world...',
    'The journey of a thousand words starts with a single c...',
  ];
  return options[Math.floor(Math.random() * options.length)];
};


export const Entry = (props) => {
  let textareaEl = useRef(null);
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const [entryPk, setEntryPk] = useState(props.entryPk);
  const [focused, setFocused] = useState(false);
  const firstUpdate = useRef(true);
  const [status, setStatus] = useState(statuses.saved);
  const [content, setContent] = useState(props.content);
  const [lastSavedContent, setLastSavedContent] = useState(props.content);
  const [lastSavedProjectId, setLastSavedProjectId] = useState(props.projectId);
  const [timeoutId, setTimeoutId] = useState(null);
  const [menuActive, setMenuActive] = useState(false);
  const [placeholder] = useState(getRandomPlaceholder());
  const [actionable, setActionable] = useState(props.actionable);
  const [completedAt, setCompletedAt] = useState(props.completed_at);
  const [autosaveCounter, setAutosaveCounter] = useState(autosaveSeconds);
  const [intervalId, setIntervalId] = useState(null);

  // TODO: keep state in sync with props for all data props
  // TODO: setContent triggers a second save after props updated
  //       from another entry instance
  useEffect(() => {
    setContent(props.content);
  }, [props.content]);

  useEffect(() => {
    autosize(textareaEl);
  }, []);

  useEffect(() => {
    // Hack to make autofocus work
    if (props.focused) {
      setTimeout(() => {
        textareaEl && textareaEl.focus();
      }, 100);
    }
  }, [props.focused]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    // Don't auto-save empty entries which don't have a primary key
    if (!content && !props.entryPk) {
      return;
    }

    props.onUpdate && props.onUpdate();

    setStatus(statuses.modified);

    if (timeoutId) clearTimeout(timeoutId);
    if (intervalId) clearInterval(intervalId);

    const newIntervalId = setInterval(() => {
      setAutosaveCounter((val) => val - 1);
    }, 1000);

    setAutosaveCounter(autosaveSeconds);

    const newTimeoutId = setTimeout(() => {
      // Don't autosave in demo mode or when content/project haven't changed
      const contentUnchanged = content === lastSavedContent;
      const projectUnchanged = props.projectId === lastSavedProjectId;
      if (props.demoMode || (contentUnchanged && projectUnchanged)) {
        setStatus(statuses.saved);
        setActionable(content.toLowerCase().indexOf('#todo') > -1);
        clearInterval(newIntervalId);
        setAutosaveCounter(autosaveSeconds);
        return;
      }

      setStatus(statuses.saving);

      let request;
      if (entryPk) {
        request = api().patch(`/api/entries/${entryPk}/`, {
          pk: props.entryPk,
          project_id: props.projectId,
          content: content,
        });
      } else {
        request = api().post(`/api/entries/`, {
          content: content,
          project_id: props.projectId,
          date: moment().format('YYYY-MM-DD'),
        });
      }

      request.then((response) => {
        setStatus(statuses.saved);
        setActionable(response.data.actionable);
        clearInterval(newIntervalId);
        setAutosaveCounter(autosaveSeconds);
        setEntryPk(response.data.pk);
        setLastSavedContent(response.data.content);
        setLastSavedProjectId(response.data.project);
        if (entryPk) {
          const actionType = props.projectview
            ? 'UPDATE_PROJECT_DETAIL_ENTRY' : 'UPDATE_ENTRY';
          dispatch({
            type: actionType,
            entry: response.data,
          });
        } else {
          const actionType = props.projectview
            ? 'ADD_PROJECT_DETAIL_ENTRY' : 'ADD_ENTRY';
          dispatch({
            type: actionType,
            entry: response.data,
          });
        }
        props.onSave && props.onSave();
      }).catch((error) => {
        console.log(error);
        setStatus(statuses.error);
        clearInterval(newIntervalId);
        setAutosaveCounter(autosaveSeconds);
      });
    }, autosaveMs);

    setIntervalId(newIntervalId);
    setTimeoutId(newTimeoutId);
  }, [content, props.projectId]);

  const handleContentChange = async (e) => {
    setContent(e.target.value);
  };

  const handleDelete = () => {
    if (props.demoMode) {
      props.onDeleted(props.entryPk);
      return;
    }

    api().delete(`/api/entries/${props.entryPk}/`).then((response) => {
      const actionType = props.projectView
        ? 'DELETE_PROJECT_DETAIL_ENTRY' : 'DELETE_ENTRY';
      dispatch({type: actionType, pk: props.entryPk});
    }).catch((error) => {
      console.log(error);
      setStatus(statuses.error);
    });
  };

  const handleCompletedClick = (completed) => {
    if (props.demoMode) {
      setStatus(statuses.saved);
      setActionable(true);
      setCompletedAt(completedAt ? null : new Date());
      return;
    }

    api().patch(`/api/entries/${props.entryPk}/`, {
      pk: props.entryPk,
      completed: completed,
    }).then((response) => {
      setStatus(statuses.saved);
      setActionable(response.data.actionable);
      setCompletedAt(response.data.completed_at);
    }).catch((error) => {
      console.log(error);
      setStatus(statuses.error);
    });
  };

  const entryClasses = classNames(styles.entry, {
    [styles.actionable]: actionable,
  });

  const showStatus = focused || status !== statuses.saved;
  const statusClasses = classNames(styles.status, {
    [styles.active]: showStatus,
    [styles.saved]: status === statuses.saved,
    [styles.saving]: status === statuses.saving,
    [styles.modified]: status === statuses.modified,
    [styles.error]: status === statuses.error,
  });

  let statusMessage;
  if (status === statuses.modified) {
    if (autosaveCounter > autosaveSeconds - 1) {
      statusMessage = 'Waiting...';
    } else {
      statusMessage = `Autosaving in ${autosaveCounter}...`;
    }
  } else if (status === statuses.saving) {
    statusMessage = 'Autosaving...';
  } else if (status === statuses.error) {
    statusMessage = 'Autosave failed';
  } else {
    statusMessage = 'Saved';
  }

  return (
    <div className={entryClasses}>
      <div className={styles.content}>
        <textarea
          rows="1"
          placeholder={placeholder}
          ref={(x) => textareaEl = x}
          value={content}
          autoFocus={props.focused}
          onChange={handleContentChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
        <div className={statusClasses}>
          <div className={styles.statusMessage}>{statusMessage}</div>
        </div>
      </div>
      {!props.hideMenu &&
        <EntryMenu
          active={menuActive}
          actionable={actionable}
          completedAt={completedAt}
          onEntryCompleted={() => handleCompletedClick(!completedAt)}
          onEntryDeleted={handleDelete}
          onMenuOpened={() => setMenuActive(true)}
          onMenuClosed={() => setMenuActive(false)}
        />
      }
    </div>
  );
};
