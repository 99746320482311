import React, {useContext, useState, useEffect} from 'react';
import {IoIosFlash} from 'react-icons/io';
import {Entry} from 'src/components/entries/entry';
import {Button} from 'src/components/ui/button';
import {Modal, ModalBody, ModalFooter} from 'src/components/ui/modal/modal';
import {
  GlobalStateContext,
} from 'src/context/globalContextProvider';
import styles from './quickEntry.module.scss';

const initializeEntryData = (projectMap) => {
  const projects = Object.keys(projectMap).filter((x) => (
    projectMap[x].uncategorized
  ));
  return {
    project: projects ? projects[0] : null,
    content: '',
  };
};

export const QuickEntry = (props) => {
  const state = useContext(GlobalStateContext);
  const [active, setActive] = useState(false);
  const [saved, setSaved] = useState(true);
  const [entryData, setEntryData] = useState(
      initializeEntryData(state.projectMap),
  );

  useEffect(() => {
    // Clear entry "in the background" if save occurs while modal is inactive
    if (saved && !active) {
      setEntryData(initializeEntryData(state.projectMap));
    }
  }, [active, saved]);

  const handleEntrySaved = (entry) => {
    setSaved(true);
  };

  const handleProjectChange = (e) => {
    setEntryData({
      ...entryData,
      project: parseInt(e.target.value),
    });
  };

  const handleDone = () => {
    setEntryData(initializeEntryData(state.projectMap));
    setActive(false);
  };

  return (
    <>
      <Button
        small
        success
        onClick={() => setActive(true)}
        leadingIcon={<IoIosFlash />}
      >
        Quick entry
      </Button>
      <Modal
        active={active}
        onMenuClosed={() => setActive(false)}
      >
        <div className={styles.quickEntryModal}>
          <ModalBody>
            <div className={styles.title}>
              <IoIosFlash />
              Quick entry
            </div>
            <div className={styles.entryWrapper}>
              {(active || !saved) && <Entry
                hideMenu
                focused={active}
                onUpdate={() => setSaved(false)}
                onSave={handleEntrySaved}
                entryPk={entryData.pk}
                content={entryData.content}
                projectId={entryData.project}
              />
              }
            </div>
            <div>
              <select value={entryData.project} onChange={handleProjectChange}>
                <optgroup label="Projects">
                  {Object.keys(state.projectMap).map((key) => (
                    <option value={key} key={`project-${key}`}>
                      {state.projectMap[key].name}
                    </option>
                  ))}
                </optgroup>
              </select>
            </div>
          </ModalBody>
          <ModalFooter rightAlign className={styles.modalFooter}>
            {/*
            <Button small onClick={handleAddAnother}>
              Save and add another
            </Button>
            */}
            <Button small onClick={handleDone}>
              Done
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};
