import classNames from 'classnames';
import React, {useEffect, useRef} from 'react';
import {Card} from 'src/components/ui/card';
import styles from './modal.module.scss';

export const Modal = (props) => {
  const backdropClasses = classNames(styles.modalBackdrop, {
    [styles.active]: props.active,
  });

  const wrapperRef = useRef(null);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      props.onMenuClosed();
    }
  };

  useEffect(() => {
    if (props.active) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      if (props.active) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [props.active]);

  return (
    <div className={backdropClasses}>
      <Card className={styles.modalWrapper}>
        <div ref={wrapperRef}>
          {props.children}
        </div>
      </Card>
    </div>
  );
};

export const ModalBody = (props) => {
  const classes = classNames(styles.modalBody, {
    [styles.active]: props.active,
  });

  return (
    <div className={classes}>
      {props.children}
    </div>
  );
};

export const ModalFooter = (props) => {
  const {active, className, rightAlign, ...extras} = props;
  const classes = classNames(styles.modalFooter, className, {
    [styles.active]: active,
    [styles.rightAlign]: rightAlign,
  });

  return (
    <div className={classes} {...extras}>
      {props.children}
    </div>
  );
};
